import './SoftwareDevelopment.css';
import Header from '../Header';
import Footer from '../Footer';
import { FaAndroid, FaApple, FaCode, FaCheckCircle } from 'react-icons/fa';

function SoftwareDevelopment() {
    return (
        <div>
            <Header />
            <div className="software-dev-container">
                <div className="software-dev-header">
                    <h1>Our Expertise in Software Development</h1>
                    <p>We transform your ideas into innovative Android, iOS, and web applications with exceptional user experiences and seamless performance.</p>
                </div>
                
                <div className="services-cards">
                    <div className="service-card">
                        <FaAndroid className="service-icon" />
                        <h2>Android Development</h2>
                        <p>Engaging, optimized Android apps with intuitive designs to elevate your business in a mobile-first world.</p>
                    </div>
                    
                    <div className="service-card">
                        <FaCode className="service-icon" />
                        <h2>Web Application Development</h2>
                        <p>Responsive and secure web solutions, tailored to your business's needs for maximum impact.</p>
                    </div>
                    
                    <div className="service-card">
                        <FaApple className="service-icon" />
                        <h2>iOS Development</h2>
                        <p>Robust iOS apps delivering exceptional performance and smooth user interactions.</p>
                    </div>
                </div>

                <div className="why-choose-us">
                    <h2>Why Choose Our Software Development Service?</h2>
                    <ul className="reasons-list">
                        <li><FaCheckCircle className="reason-icon" /> <span className="reason-text"><b>Expertise:</b> Decades of combined experience in mobile and web development.</span></li>
                        <li><FaCheckCircle className="reason-icon" /> <span className="reason-text"><b>Customization:</b> Tailored solutions to address your specific business needs.</span></li>
                        <li><FaCheckCircle className="reason-icon" /> <span className="reason-text"><b>Performance:</b> Seamless user experiences with optimized performance.</span></li>
                        <li><FaCheckCircle className="reason-icon" /> <span className="reason-text"><b>Support:</b> Dedicated support throughout your project lifecycle.</span></li>
                        <li><FaCheckCircle className="reason-icon" /> <span className="reason-text"><b>Agile Delivery:</b> Fast development cycles to bring your product to market quickly.</span></li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SoftwareDevelopment;
