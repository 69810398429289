import './Equipment.css';
import Header from '../Header';
import Footer from '../Footer';
import { FaDesktop, FaServer, FaTools, FaCheckCircle, FaCogs } from 'react-icons/fa';

function Equipment() {
    return (
        <div>
            <Header />
            <div className="equipment-container">
                <div className="equipment-header">
                    <h1>Equipment Supply</h1>
                    <p>Providing a comprehensive range of computer hardware and software solutions tailored to meet your business needs, ensuring optimal performance and reliability.</p>
                </div>

                <div className="equipment-details">
                    <div className="equipment-detail">
                        <FaDesktop className="detail-icon desktop-icon" />
                        <h2>Computer Hardware</h2>
                        <p>We supply high-quality desktop computers, laptops, and peripherals, ensuring that your business operations run smoothly with reliable and efficient hardware.</p>
                    </div>

                    <div className="equipment-detail">
                        <FaServer className="detail-icon server-icon" />
                        <h2>Networking Equipment</h2>
                        <p>Our range includes switches, routers, and other networking equipment to set up and maintain robust and secure networks for your business.</p>
                    </div>

                    <div className="equipment-detail">
                        <FaCogs className="detail-icon software-icon" />
                        <h2>Software Solutions</h2>
                        <p>We offer a wide selection of software solutions, including operating systems, productivity tools, and specialized software to meet your unique business requirements.</p>
                    </div>
                </div>

                <div className="why-choose-equipment">
                    <h2>Why Choose Our Equipment Supply?</h2>
                    <div className="reasons-container">
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Quality Products:</strong>
                                High-quality and reliable hardware and software from trusted brands.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Expert Recommendations:</strong>
                                Tailored recommendations based on your specific business needs.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Comprehensive Support:</strong>
                                End-to-end support from selection to installation and maintenance.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Competitive Pricing:</strong>
                                Competitive pricing without compromising on quality or service.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Timely Delivery:</strong>
                                Fast and reliable delivery to keep your business operations on track.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Equipment;
