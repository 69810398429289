import './CyberSecurity.css';
import Header from '../Header';
import Footer from '../Footer';
import { FaShieldAlt, FaNetworkWired, FaBug, FaCheck } from 'react-icons/fa'; // Updated icon imports

function CyberSecurity() {
    return (
        <div>
            <Header />
            <div className="cybersecurity-container">
                <div className="cybersecurity-header">
                    <h1>Cyber Security Services</h1>
                    <p>Protect your digital assets with our comprehensive cyber security solutions. We offer a range of services to safeguard your business from evolving threats and vulnerabilities.</p>
                </div>

                <div className="cybersecurity-details">
                    <div className="cybersecurity-detail">
                        <FaShieldAlt className="detail-icon protection-icon" />
                        <h2>Threat Protection</h2>
                        <p>Advanced protection against a wide range of cyber threats, including malware, ransomware, and phishing attacks.</p>
                    </div>

                    <div className="cybersecurity-detail">
                        <FaNetworkWired className="detail-icon network-icon" />
                        <h2>Network Security</h2>
                        <p>Comprehensive network security solutions to protect your infrastructure from breaches and vulnerabilities.</p>
                    </div>

                    <div className="cybersecurity-detail">
                        <FaBug className="detail-icon vulnerability-icon" />
                        <h2>Vulnerability Assessment</h2>
                        <p>Regular assessments to identify and mitigate potential vulnerabilities in your systems and applications.</p>
                    </div>
                </div>

                <div className="why-choose-cybersecurity">
                    <h2>Why Choose Our Cyber Security Services?</h2>
                    <div className="reasons-container">
                        <div className="reason-item">
                            <FaCheck className="reason-icon" />
                            <div className="reason-text">
                                <strong>Comprehensive Coverage:</strong>
                                <p>We provide a full range of cyber security services to protect every aspect of your digital environment.</p>
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheck className="reason-icon" />
                            <div className="reason-text">
                                <strong>Expert Team:</strong>
                                <p>Our team of experienced professionals is skilled in the latest cyber security techniques and tools.</p>
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheck className="reason-icon" />
                            <div className="reason-text">
                                <strong>Proactive Approach:</strong>
                                <p>We adopt a proactive approach to identify and address potential threats before they can impact your business.</p>
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheck className="reason-icon" />
                            <div className="reason-text">
                                <strong>Customized Solutions:</strong>
                                <p>Tailored security solutions designed to meet the specific needs and risks associated with your business.</p>
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheck className="reason-icon" />
                            <div className="reason-text">
                                <strong>Continuous Monitoring:</strong>
                                <p>Ongoing monitoring to detect and respond to potential security incidents in real-time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CyberSecurity;


