import React from "react";
import Header from "./Header";
import Key from "./Key";
import Service from "./Service";
import Technology from "./Technology";
import Reason from "./Reason";
import Footer from "./Footer";
import Comments from "./Comments";

import './Home.css';

function Home(){
    return(
        <div className="home">
      <Header/>
      <Key/>
      <Service/>
      <Technology/>
      <Reason/>
      <Footer/>
        </div>
    );
}

export default Home