import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Key from './components/Key';
import Service from './components/Service';
import Technology from './components/Technology';
import Reason from './components/Reason';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Home from './components/Home';
import ContactUs from './components/ContactUs';


import SoftwareDevelopment from './components/ServiceComponent/SoftwareDevelopment';
import Networking from './components/ServiceComponent/Networking';
import Equipment from './components/ServiceComponent/Equipment';
import Consultant from './components/ServiceComponent/Consultant';
import CyberSecurity from './components/ServiceComponent/CyberSecurity';
import Support from './components/ServiceComponent/Support';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/aboutUs" element={<AboutUs/>} />
           <Route path='/key' element={<Key/>}/>          
           <Route path='/technology' element={<Technology/>}/> 
           <Route path='/reason' element={<Reason/>}/> 
           <Route path='/footer' element={<Footer/>}/> 
           <Route path='/service' element={<Service/>}/> 
           <Route path='/header' element={<Header/>}/> 
           <Route path='/contactUs' element = {<ContactUs/>}/>

          <Route path='/software' element={<SoftwareDevelopment/>}/> 
          <Route path='/networking' element={<Networking/>}/> 
          <Route path='/equipment' element={<Equipment/>}/> 
          <Route path='/consultant' element={<Consultant/>}/> 
          <Route path='/cyber' element={<CyberSecurity/>}/> 
          <Route path='/support' element={<Support/>}/> 

        </Routes>
      </Router>
    </div>
  );
}

export default App;
