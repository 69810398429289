import './Key.css';
import whatsapp from '../images/whatsapp.png';
import React, { useEffect } from 'react';
import { FaTachometerAlt, FaDollarSign, FaBusinessTime } from 'react-icons/fa';

function Key() {
  useEffect(() => {
    const solutions = document.querySelectorAll('.solution');

    const options = {
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    solutions.forEach(solution => {
      observer.observe(solution);
    });

    return () => {
      solutions.forEach(solution => {
        observer.unobserve(solution);
      });
    };
  }, []);

  return (
    <div className='key'>
      <div className='left-key'>
        <h1>Techmento Solution Resolves 3 Key Problems For Business</h1>
        <h3>We’ve always known that for businesses large and small, 
          IT can be a real challenge to manage. Staying on top of
          your technology includes controlling the costs associated
          with keeping in-house staff up-to-date with training, 
          certifications, and current trends.
        </h3>
      </div>
      <div className='whatsapp'>
        <a href="https://wa.me/+255775666800?text=Hii%20Techmento" target='_blank' className='whatsapp-icon' rel="noopener noreferrer">
          <img src={whatsapp} alt='Whatsapp'></img>
        </a>
        <p>Need Help? &nbsp;</p>
      </div>
      <div className='right-key'>
        <div className='solution'>
          <FaTachometerAlt className='solution-icon' style={{ color: '#02a9f7' }} />
          <div className='solution-content'>
            <h2>Poor IT Performance & Service Delivery</h2>
            <p>Poorly performing IT Partners and IT Services reduce
              productivity and risk the integrity of your business.
            </p>
          </div>
        </div>
        <div className='solution'>
          <FaDollarSign className='solution-icon' style={{ color: '#02a9f7' }} />
          <div className='solution-content'>
            <h2>Controlling IT Costs</h2>
            <p>IT Services are one of the largest sunken or ongoing
              costs in any business, therefore, important to manage.
            </p>
          </div>
        </div>
        <div className='solution'>
          <FaBusinessTime className='solution-icon' style={{ color: '#02a9f7' }} />
          <div className='solution-content'>
            <h2>Unexpected Technical Issues</h2>
            <p>IT issues can occur anytime, hindering productivity and
              causing business disruptions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Key;
