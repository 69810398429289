import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import './AboutUs.css';

function AboutUs() {
    return (
        <div>
            <Header />
            <div className="about-us-container">
                <div className="about-us-header">
                    <h1>Welcome to Techmento</h1>
                    <p>Your trusted partner in IT solutions</p>
                    <p className="slogan">"Redefining IT Possibilities"</p>
                </div>

                <div className="about-us-content">
                    <div className="company-description">
                        <h2>About Us</h2>
                        <p>Techmento is a leading IT solutions provider dedicated to delivering innovative and tailored technology services. With a team of experienced professionals, we offer cutting-edge solutions that drive business success and efficiency. Our expertise spans across software development, IT support, and network solutions, all designed to meet the evolving needs of our clients.</p>
                    </div>

                    <div className="company-vision">
                        <h2>Our Vision</h2>
                        <p>At Techmento, our vision is to be the premier IT partner that empowers businesses to harness the full potential of technology. We aim to revolutionize the way companies operate by providing transformative IT solutions that enhance productivity, drive innovation, and achieve long-term success. Our commitment is to deliver excellence and make a meaningful impact in the tech industry.</p>
                    </div>

                    <div className="company-mission">
                        <h2>Our Mission</h2>
                        <p>Our mission is to deliver exceptional IT solutions that address our clients' unique challenges and opportunities. We strive to offer unparalleled service through a customer-centric approach, ensuring that our solutions are not only effective but also align with our clients' strategic goals. Our goal is to be a trusted advisor and partner, committed to helping our clients achieve their business objectives through technology.</p>
                    </div>

                    <div className="company-values">
                        <h2>Core Values</h2>
                        <ul>
                            <li>Innovation: Continuously advancing and evolving to provide the latest and most effective technology solutions.</li>
                            <li>Integrity: Upholding the highest standards of honesty and transparency in all our interactions.</li>
                            <li>Customer Focus: Prioritizing the needs and success of our clients to deliver exceptional results.</li>
                            <li>Excellence: Committing to the highest levels of quality and professionalism in every project.</li>
                            <li>Collaboration: Fostering a collaborative environment to drive creativity and success.</li>
                        </ul>
                    </div>

                    <div className="company-services">
                        <h2>Our Services</h2>
                        <ul>
                            <li>Custom Software Development</li>
                            <li>Equipment Supply and Networking</li>
                            <li>IT Support and Maintenance</li>
                            <li>IT Consultancy</li>
                            <li>Cyber Security Solutions</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;
