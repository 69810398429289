import React, { useEffect, useState } from 'react';
import { FaNetworkWired, FaCode, FaCloud, FaHeadset, FaChartLine, FaShieldAlt } from 'react-icons/fa';
import './Service.css';

const services = [
  { title: 'Network Design and Implementation', description: 'Designing and implementing efficient network solutions tailored to your business needs.', icon: <FaNetworkWired />, animation: 'slide-left' },
  { title: 'Software Development and Integration', description: 'Creating and integrating software solutions that enhance productivity and streamline processes.', icon: <FaCode />, animation: 'slide-right' },
  { title: 'Cloud Computing Solutions', description: 'Offering scalable cloud solutions to optimize your IT infrastructure and reduce costs.', icon: <FaCloud />, animation: 'slide-top' },
  { title: 'IT Support and Maintenance', description: 'Providing reliable IT support and maintenance services to keep your systems running smoothly.', icon: <FaHeadset />, animation: 'slide-bottom' },
  { title: 'IT Strategy and Planning', description: 'Expert consultancy to develop strategic IT plans for sustainable growth and innovation.', icon: <FaChartLine />, animation: 'slide-left' },
  { title: 'Cyber Security', description: 'Ensuring the security of your digital assets with comprehensive cyber security measures.', icon: <FaShieldAlt />, animation: 'slide-right' },
];

function Service() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    const serviceSection = document.querySelector('.service-section');
    if (serviceSection) observer.observe(serviceSection);

    return () => observer.disconnect();
  }, []);

  return (
    <div className='service-section'>
      <h2 className='service-intro'>Our Services</h2>
      <p className='service-subintro'>We provide a wide range of IT solutions to help your business thrive.</p>
      <div className='service-container'>
        {services.map((service, index) => (
          <div
            key={index}
            className={`service-card ${isVisible ? `animation-active ${service.animation}` : ''}`} // Add animation class when visible
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <div className='service-icon'>{service.icon}</div>
            <h3 className='service-header'>{service.title}</h3>
            <p className='service-description'>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Service;
