import './Support.css';
import Header from '../Header';
import Footer from '../Footer';
import { FaHeadset, FaNetworkWired, FaTools, FaCheckCircle } from 'react-icons/fa';

function Support() {
    return (
        <div>
            <Header />
            <div className="support-container">
                <div className="support-header">
                    <h1>IT Support and Maintenance</h1>
                    <p>Offering comprehensive IT support and maintenance services to ensure your systems are running smoothly. Our team provides expert troubleshooting, network support, and ongoing maintenance to keep your technology in top shape.</p>
                </div>

                <div className="support-details">
                    <div className="support-detail">
                        <FaHeadset className="detail-icon support-icon" />
                        <h2>24/7 Technical Support</h2>
                        <p>Our dedicated support team is available around the clock to assist you with any technical issues and ensure minimal downtime.</p>
                    </div>

                    <div className="support-detail">
                        <FaNetworkWired className="detail-icon network-icon" />
                        <h2>Network Troubleshooting</h2>
                        <p>Expert network troubleshooting services to quickly diagnose and resolve connectivity issues, ensuring seamless and reliable network performance.</p>
                    </div>

                    <div className="support-detail">
                        <FaTools className="detail-icon tools-icon" />
                        <h2>System Maintenance</h2>
                        <p>Regular system maintenance and updates to keep your IT infrastructure running efficiently and to protect against potential issues.</p>
                    </div>
                </div>

                <div className="why-choose-support">
                    <h2>Why Choose Our IT Support and Maintenance Services?</h2>
                    <div className="reasons-container">
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>24/7 Availability:</strong>
                                Access to expert support at any time to resolve issues and ensure your systems are always operational.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Fast Response Time:</strong>
                                Quick response to technical issues to minimize downtime and keep your business running smoothly.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Comprehensive Coverage:</strong>
                                Wide range of support services covering all aspects of IT infrastructure and systems.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Proactive Maintenance:</strong>
                                Regular system checks and updates to prevent potential issues and enhance system performance.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Expert Team:</strong>
                                A skilled team of IT professionals with extensive experience in providing top-notch support and maintenance.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Support;
