import './Networking.css';
import Header from '../Header';
import Footer from '../Footer';
import { FaNetworkWired, FaLink, FaShieldAlt, FaCheckCircle } from 'react-icons/fa';

function Networking() {
    return (
        <div>
            <Header />
            <div className="networking-container">
                <div className="networking-header">
                    <h1>Networking Solutions</h1>
                    <p>Providing reliable and scalable networking solutions to ensure seamless connectivity and efficient communication within your organization. Our services include network design, installation, and management to meet your unique business needs.</p>
                </div>

                <div className="networking-details">
                    <div className="networking-detail">
                        <FaNetworkWired className="detail-icon network-icon" />
                        <h2>Network Design</h2>
                        <p>Expert network design services to create efficient, scalable, and secure network infrastructures tailored to your business requirements.</p>
                    </div>

                    <div className="networking-detail">
                        <FaLink className="detail-icon link-icon" />
                        <h2>Network Installation</h2>
                        <p>Professional installation of routers, switches, and other networking equipment to ensure a robust and reliable network setup for your business.</p>
                    </div>

                    <div className="networking-detail">
                        <FaShieldAlt className="detail-icon security-icon" />
                        <h2>Network Security</h2>
                        <p>Comprehensive network security solutions to protect your business from cyber threats, ensuring data integrity and secure communications.</p>
                    </div>
                </div>

                <div className="why-choose-networking">
                    <h2>Why Choose Our Networking Solutions?</h2>
                    <div className="reasons-container">
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Reliable Connectivity:</strong>
                                Ensure uninterrupted and stable network connectivity for seamless operations.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Custom Solutions:</strong>
                                Tailored network solutions designed to fit your specific business needs and scale with your growth.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Expert Installation:</strong>
                                Professional installation and configuration to ensure optimal performance and security.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Advanced Security:</strong>
                                Cutting-edge security measures to protect against cyber threats and unauthorized access.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Ongoing Support:</strong>
                                Continuous support and maintenance to address any issues and keep your network running smoothly.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Networking;
