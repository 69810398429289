import React from "react";
import './Reason.css';

const reasons = [
    {
        icon: "🌟",
        title: "Expert Team",
        description: "Our team consists of industry experts with extensive experience and skills to tackle any challenge."
    },
    {
        icon: "💡",
        title: "Innovative Solutions",
        description: "We provide innovative solutions that drive business growth and technological advancement."
    },
    {
        icon: "📈",
        title: "Proven Results",
        description: "Our track record of successful projects speaks for itself, delivering results that exceed expectations."
    },
    {
        icon: "🤝",
        title: "Client-Centric Approach",
        description: "We focus on understanding your needs and providing tailored solutions that fit your specific requirements."
    },
    {
        icon: "🚀",
        title: "Cutting-Edge Technology",
        description: "We utilize the latest technologies to ensure high performance and scalability for your projects."
    }
];

function Reason() {
    return (
        <div className="myreason-section">
            <div className="myreason-left-circle">
                <div className="myreason-left-text">
                    <h2>WHY CHOOSE US</h2>
                </div>
            </div>
            <div className="myreason-main-circle">
                <div className="myreason-center-text">
                    <p>We are a team of dedicated professionals providing solutions to your business.</p>
                </div>
                <div className="myreason-right-circles">
                    {reasons.map((reason, index) => (
                        <div key={index} className={`myreason-icon-circle myreason-color-${index + 1}`}>
                            <div className="myreason-icon">{reason.icon}</div>
                            <div className="myreason-arrow"></div>
                            <div className="myreason-text">
                                <h3 className="myreason-title">{reason.title}</h3>
                                <p className="myreason-description">{reason.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Reason;
