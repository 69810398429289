import './Header.css';
import logo from '../images/logo.png';
import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons

const Header = () => {
    const images = [
        require('../images/header2.jpg'),
        require('../images/header3.jpg'),
        require('../images/header4.jpg'),
        require('../images/header5.jpg')
    ];

    const [currentImage, setCurrentImage] = useState(0);
    const [sidebarActive, setSidebarActive] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [images.length]);

    const toggleSidebar = () => {
        setSidebarActive(!sidebarActive);
    };

    return (
        <div>
            <div className='header' style={{ backgroundImage: `url(${images[currentImage]})` }}>
                <div className='logo'>
                    <img src={logo} height="50px" width="200px" alt="logo" />
                    <br />
                    <p id="slogan"><span className='first'>Redefining IT </span><span className='last'>Possibilities</span></p>
                </div>
                <div className='links'>
                    <a href="/" className='toplink'><b>HOME</b></a>
                    <div className="dropdown">
                        <button className="dropbtn"><b id="service">SERVICES</b></button>
                        <div className="dropdown-content">
                            <a href="/software">Software Development</a>
                            <a href="/equipment">Equipment Supply</a>
                            <a href="/networking">Networking</a>
                            <a href="/support">IT Support and Maintenance</a>
                            <a href="/consultant">IT Consultancy</a>
                            <a href="/cyber">Cyber Security</a>
                        </div>
                    </div>
                    <a href="/aboutUs" className='toplink'><b>ABOUT US</b></a>
                    <a href="/contactUs" className='toplink'><b>CONTACT US</b></a>
                </div>
                
                <FaBars className="menu-icon" onClick={toggleSidebar} />
            </div>
            <div className={`sidebar ${sidebarActive ? 'active' : ''}`}>
                <FaTimes className='close-btn' onClick={toggleSidebar} />
                <a href="/">HOME</a>
                <div className="sidebar-services">
                    <a href="/software">Software Development and Integration</a>
                    <a href="/equipment">Cloud Computing Solutions</a>
                    <a href="/networking">Network Design and Implementation</a>
                    <a href="/support">IT Support and Maintenance</a>
                    <a href="/consultant">IT Strategy and Planning</a>
                    <a href="/cyber">Cyber Security</a>
                </div>
                <a href="/aboutUs">ABOUT US</a>
                <a href="/contactUs">CONTACT US</a>
            </div>
            <div className='headerbody'>
                <h1>We're Techmento</h1>
                <h2>Our services include building personalized 
                    websites and<br /> mobile apps, coupled with advanced 
                    IT consultation to<br /> enhance your productivity and improve processes.
                </h2>
            </div>
        </div>
    );
}

export default Header;
