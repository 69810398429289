import './Consultant.css';
import Header from '../Header';
import Footer from '../Footer';
import { FaLightbulb, FaCogs, FaChartLine, FaHandshake, FaBullhorn, FaCheckCircle } from 'react-icons/fa'; // Add FaCheckCircle import

function Consultant() {
    return (
        <div>
            <Header />
            <div className="consultant-container">
                <div className="consultant-header">
                    <h1>IT Consultancy Services</h1>
                    <p>Our IT consultancy services provide expert guidance and solutions to help you leverage technology for business growth. We offer strategic advice, technology assessments, and implementation support tailored to your unique needs.</p>
                </div>

                <div className="consultant-details">

                    <div className="consultant-detail">
                        <FaCogs className="detail-icon implementation-icon" />
                        <h2>Technology Implementation</h2>
                        <p>Expert implementation of technology solutions to ensure seamless integration and effective utilization within your organization.</p>
                    </div>


                    <div className="consultant-detail">
                        <FaHandshake className="detail-icon collaboration-icon" />
                        <h2>Collaborative Approach</h2>
                        <p>Work closely with your team to understand your needs and provide solutions that are both practical and innovative.</p>
                    </div>

                    <div className="consultant-detail">
                        <FaBullhorn className="detail-icon communication-icon" />
                        <h2>Clear Communication</h2>
                        <p>Maintain clear and transparent communication throughout the consulting process to ensure all objectives are met effectively.</p>
                    </div>
                </div>

                <div className="why-choose-consultancy">
                    <h2>Why Choose Our IT Consultancy Services?</h2>
                    <div className="reasons-container">
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Expert Guidance:</strong>
                                Access to seasoned consultants who offer deep industry knowledge and practical advice.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Tailored Solutions:</strong>
                                Customized strategies and solutions designed to fit your specific business needs and objectives.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Proven Track Record:</strong>
                                Successful track record of delivering value through technology and process improvements.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Holistic Approach:</strong>
                                Comprehensive approach addressing all aspects of technology and business integration.
                            </div>
                        </div>
                        <div className="reason-item">
                            <FaCheckCircle className="reason-icon" />
                            <div className="reason-text">
                                <strong>Continuous Support:</strong>
                                Ongoing support and advice to ensure the successful implementation and ongoing success of solutions.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Consultant;
